<template>
    <Card title="Timeline">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" :class="{active: collection === 'education_timeline'}" role="button" data-bs-toggle="tab" @click="collection ='education_timeline'">Education</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{active: collection === 'experience_timeline'}" role="button" data-bs-toggle="tab" @click="collection = 'experience_timeline'">Profile</a>
            </li>
        </ul>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Local</th>
                    <th>Location</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in timeline" :key="item.id">
                    <td>{{ item.title }}</td>
                    <td>{{ item.local }}</td>
                    <td>{{ item.location }}</td>
                    <td><button role="button" class="btn btn-link btn-sm" @click="$router.push({ name: 'AdminTimelineEdit', params: { collection, item } })">edit</button></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="99">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-outline-success" @click="$router.push({ name: 'AdminTimelineEdit', params: { collection } })">
                                <i class="fas fa-plus"></i> CREATE NEW TIMELINE
                            </button>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    data() {
        return {
            collection: 'education_timeline',
            timeline: []
        }
    },
    methods: {
        getData() {
            firebase.firestore().collection(this.collection || 'education_timeline').onSnapshot(({ docs }) => {
                this.timeline = docs.map(doc => {
                    return { ...doc.data(), id: doc.id }
                })
            })
        },
        setCollection(collection) {
            this.collection = collection
        }
    },
    watch: {
        collection(val) {
            this.getData()
        }
    },
    mounted() {
        this.getData()
    }
}
</script>