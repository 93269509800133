<template>
    <section>
        <div class="card border-primary my-auto">
            <div class="card-header">
                <h1 class="p-4">{{ title }}</h1>
            </div>
            <div class="card-body">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: [
        'title'
    ]
}
</script>

<style scoped>
.card {
    width: 100%;
    max-width: 900px;
    height: calc(100vh - 40px);
    overflow-y: auto;
}
</style>